// Used for Czech, Slovak.

export default function pluralize(count) {
  if (count === 1) {
    return ['one'];
  } if ([2, 3, 4].includes(count)) {
    return ['few'];
  }

  return ['other'];
}
